import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ViewDayOutlinedIcon from "@mui/icons-material/ViewDayOutlined";
import PowerInputOutlinedIcon from "@mui/icons-material/PowerInputOutlined";

const measure = {
  id: "group-measure",
  title: "Measure",
  admin: false,
  type: "group",
  children: [
    {
      id: "measure-energy",
      title: "발전현황",
      type: "item",
      url: "/measure",
      icon: SsidChartOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "measure-system",
      title: "시스템 현황",
      type: "item",
      url: "/measure/system",
      icon: DisplaySettingsOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "measure-jb",
      title: "접속반 현황",
      type: "item",
      url: "/measure/jb",
      icon: ViewDayOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "measure-event",
      title: "이벤트 현황",
      type: "item",
      url: "/measure/event",
      icon: AccessAlarmOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "measure-mppt",
      title: "MPPT 조회",
      type: "item",
      url: "/measure/mppt",
      icon: PowerInputOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default measure;
