import { useState, useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { auth } from "../utils/auth";
import { axios } from "../common";
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const _ = ({ children, isAdmin = false }) => {
  const [pending, setPending] = useState(false);
  const { user, setUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    (async function loadAccount() {
      if (!pending) {
        if (auth.isAuthenticated()) {
          try {
            const { status, data } = await axios.get("/accounts");

            if (status === 200) {
              setUser(data);
              setPending(true);
            }
          } catch (e) {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      }
    })();
  }, [pending, setUser]);

  const renderLogin = useMemo(() => {
    return !user ? (
      <Navigate to="/login" state={{ from: location.pathname }} replace />
    ) : (
      children
    );
  }, [children, location.pathname, user]);

  //   if (!user) {
  //     return <Navigate to="/login" />;
  //   }

  //return <>{!user ? <Navigate to="/login" /> : children}</>;
  return !pending ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    renderLogin
  );
};

export default _;

_.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
};
