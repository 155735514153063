import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";

const dashboard = {
  id: "group-dashboard",
  title: "Dashboard",
  admin: false,
  type: "group",
  children: [
    {
      id: "combine",
      title: "통합대시보드",
      type: "item",
      url: "/dashboard-reform",
      icon: DashboardOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "respectively",
      title: "개별대시보드",
      type: "item",
      url: "/dashboard/respectively",
      icon: DesktopWindowsOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "predict",
      title: "예측대시보드",
      type: "item",
      url: "/dashboard/predict",
      icon: AddchartOutlinedIcon,
      breadcrumbs: false,
    },

    // {
    //   id: "region",
    //   title: "지역별",
    //   type: "item",
    //   url: "/dashboard/region",
    //   icon: AddchartOutlinedIcon,
    //   breadcrumbs: false,
    // },
  ],
};

export default dashboard;
