import { lazy } from "react";

import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import DashboardNew from "../pages/dashboard-new/DashboardNew";

const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("pages/authentication/ResetPassword"))
);

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    { path: "login", element: <AuthLogin /> },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
    {
      path: "dashboardnew",
      element: <DashboardNew />,
    },
  ],
};

export default LoginRoutes;
