import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerOutlined";
//import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
//import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
//import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import DevicesIcon from "@mui/icons-material/Devices";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";

const admin = {
  id: "group-admin",
  title: "관리자",
  admin: true,
  type: "group",
  children: [
    {
      id: "plantlist",
      title: "발전소 관리",
      type: "item",
      url: "/admin/plant",
      icon: SolarPowerOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "minusplant",
      title: "마이너스발전소",
      type: "item",
      url: "/admin/plant/minus",
      icon: AssignmentLateOutlinedIcon,
      breadcrumbs: false,
    },
    // {
    //   id: "plantcreate",
    //   title: "발전소 등록",
    //   type: "item",
    //   url: "/admin/plant/create",
    //   icon: AddHomeWorkOutlinedIcon,
    //   breadcrumbs: true,
    // },

    {
      id: "userlist",
      title: "사용자 관리",
      type: "item",
      url: "/admin/user",
      icon: GroupOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "devicelist",
      title: "장치 관리",
      type: "item",
      url: "/admin/device",
      icon: DevicesIcon,
      breadcrumbs: true,
    },

    {
      id: "facility",
      title: "유지관리",
      type: "item",
      url: "/admin/facility",
      icon: ConstructionOutlinedIcon,
      breadcrumbs: true,
    },

    // {
    //   id: "usercreate",
    //   title: "사용자 등록",
    //   type: "item",
    //   url: "/admin/user/create",
    //   icon: PersonAddAlt1OutlinedIcon,
    //   breadcrumbs: true,
    // },

    // {
    //   id: "facility",
    //   title: "유지 관리",
    //   type: "item",
    //   url: "/admin/facility",
    //   icon: ConstructionOutlinedIcon,
    //   breadcrumbs: true,
    // },
  ],
};

export default admin;
